import { Field, Label, Table as AosTable } from '@aos/styleguide-react';
import { useTranslationText } from '../translation/TranslationHooks';
import LinkTableCell from '../ui/link-table-cell/LinkTableCell';
import LoadingSpinner from '../ui/loading-spinner/LoadingSpinner';
import TableHeader, { TableColumn } from '../ui/table-header/TableHeader';
import './Systems.scss';
import ActionBar from '../ui/action-bar/ActionBar';
import { UserResourcePermissions } from '../auth/AuthUserRoles';
import { useGetSystems } from '../react-query/SystemApi';
import NavigationRoutes from '../routing/NavigationRoutes';
import NavigateButton from '../generic-components/navigate-button/NavigateButton';

const tableColumns: TableColumn[] = [
  {
    translationKey: 'name',
    property: 'name',
    disableSort: true,
  },
  {
    translationKey: 'supportContactPerson',
    property: 'supportContactPerson',
    disableSort: true,
  },
  {
    translationKey: 'stages',
    property: 'stages',
    disableSort: true,
  },
];

export default function Systems() {
  const { t } = useTranslationText('systems');
  const { t: tError } = useTranslationText('errorTexts');
  const { t: tCommons } = useTranslationText('commons');
  const { isLoading, data: systems, isError } = useGetSystems({});

  return (
    <>
      <ActionBar
        right={
          <Field>
            <Label size="is-small">{tCommons('moreActions')}</Label>
            <NavigateButton
              roleCheckKey="create-new-system-btn"
              requiredPermission={UserResourcePermissions.System.Create}
              to="create"
              text={t('createNewSystem')}
              size="is-small"
              icon="add"
              isConfirm
            />
          </Field>
        }
      />
      <LoadingSpinner isLoading={isLoading} errors={isError ? tError('404_system') : undefined}>
        <div>
          <AosTable>
            <TableHeader columns={tableColumns} translationSection="systems" currentSort={undefined} />
            <AosTable.Body>
              {systems?.map((system) => {
                const routeToDetail = NavigationRoutes.SystemId(system.id);
                return (
                  <AosTable.Row className="is-clickable" key={system.id}>
                    <LinkTableCell className="system-type-name" to={routeToDetail}>
                      {system.name}
                    </LinkTableCell>
                    <LinkTableCell to={routeToDetail}>
                      <div className="system-support-contact-person">{system.supportContactPerson}</div>
                    </LinkTableCell>
                    <LinkTableCell to={routeToDetail}>
                      {system.stages?.map((stage) => stage.name).join(', ')}
                    </LinkTableCell>
                  </AosTable.Row>
                );
              })}
            </AosTable.Body>
          </AosTable>
        </div>
      </LoadingSpinner>
    </>
  );
}
