import { MultiValue } from 'react-select';
import { useTranslationText } from '../../translation/TranslationHooks';
import { ReactSelectOption } from '../../ui/search-dropdown/SearchDropdown';
import MultiSelectDropdown from '../../ui/multi-select-dropdown/MultiSelectDropdown';
import { ActiveAlertColumn } from '../../models/operation/AlertModel';
import { useColumnConfigContext } from './ColumnConfig';

enum ActiveAlertSpecialColumn {
  Name = "Name",
  Timestamp = "Zeitstempel",
  ComponentType = "Komponententyp",
  Contract = "Vertrag",
  Title = "Betriebsstatus (Metrik)",
  Criticality = "Kritikalität",
}

const mapColumnsToOptions = (columns: ActiveAlertColumn[]) =>
  columns.map((column) => ({
    value: column.headerText,
    label: column.headerText,
  }));

export function ColumnVisibilityDropdown() {
  const { t } = useTranslationText('components');
  const { columnConfig, dispatch } = useColumnConfigContext();
  const handleOnChange = (selectedOptions: MultiValue<ReactSelectOption>): void => {
    const selectedValues: string[] = selectedOptions.map((option) =>  option.value);
    if(!selectedValues.includes(ActiveAlertSpecialColumn.Name)) {
      selectedValues.push(ActiveAlertSpecialColumn.Name)
    }
    columnConfig.forEach((column) =>
      selectedValues.includes(column.headerText)
        ? dispatch({ type: 'set-visible', configToChange: column.headerText })
        : dispatch({ type: 'set-invisible', configToChange: column.headerText }),
    );
  };

  return (
    <MultiSelectDropdown
      dataRole="column-select"
      options={mapColumnsToOptions(columnConfig)}
      onChange={handleOnChange}
      isSearchable
      openMenuOnClick
      mappedValues={mapColumnsToOptions(columnConfig.filter((column) => column.visible))}
      placeholder={t('selectcolumns')}
    />
  );
}
