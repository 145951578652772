import { useLocation, useNavigate } from 'react-router';
import { faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SingleValue } from 'react-select';
import SingleSelectDropdown, { ReactSelectOption } from '../../ui/single-select-dropdown/SingleSelectDropdown';
import Collapsible from '../../ui/collapsible/Collapsible';
import { UserResourcePermissions } from '../../auth/AuthUserRoles';
import { useGetAllContractors } from '../../react-query/ContractManagementSystemApi';
import LoadingSpinner from '../../ui/loading-spinner/LoadingSpinner';
import { useTranslationText } from '../../translation/TranslationHooks';
import { Contractor } from '../../models/contract-management/ContractorModel';
import DetailInfoField from '../../ui/detail-info-field/DetailInfoField';
import NavigationRoutes from '../../routing/NavigationRoutes';
import './ChangeableContractor.scss';

interface ContractorDetailProps {
  contractor: Contractor;
}

function ContractorDetails({ contractor }: ContractorDetailProps) {
  const { t } = useTranslationText('contractManagements');

  return (
    <>
      <div className="columns">
        <div className="column">
          <DetailInfoField label={t('streetLabel')} value={contractor.street} />
        </div>
        <div className="column is-one-quarter">
          <DetailInfoField label={t('houseNumberLabel')} value={contractor.houseNumber} />
        </div>
      </div>
      <div className="columns">
        <div className="column is-half ">
          <DetailInfoField label={t('postalCodeLabel')} value={contractor.postalCode} />
        </div>
        <div className="column">
          <DetailInfoField label={t('cityLabel')} value={contractor.city} />
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <DetailInfoField label={t('phoneNumberLabel')} value={contractor.phoneNumber} />
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <DetailInfoField label={t('faxNumberLabel')} value={contractor.faxNumber} />
        </div>
      </div>
      <div className="columns">
        <div className="column is-fullwidth">
          <DetailInfoField label={t('emailLabel')} value={contractor.email} />
        </div>
      </div>
    </>
  );
}

interface ChangeableContractorParams {
  onChange: (selectedOption: SingleValue<ReactSelectOption<Contractor>>) => void;
  contractor: Contractor | undefined;
}

function mapToOption(contractor: Contractor): ReactSelectOption<Contractor> {
  return {
    label: contractor.companyName,
    value: contractor,
  };
}

export default function ChangeableContractor({ contractor, onChange }: ChangeableContractorParams) {
  const { data: contractorsPaged, isLoading: isLoadingContractors } = useGetAllContractors();

  const navigate = useNavigate();
  const location = useLocation();

  const { t } = useTranslationText('contractManagements');

  return (
    <LoadingSpinner isLoading={isLoadingContractors}>
      <Collapsible collapsibleKey="contractor" initialExpanded title="Vertragspartner">
        <div className="changeable-contractor">
          <div className="dropdown is-fullwidth">
            <SingleSelectDropdown
              dataRole="contractor-select"
              label={t('companyNameLabel')}
              options={contractorsPaged?.content?.map((it) => mapToOption(it)) || []}
              onChange={onChange}
              value={contractor ? mapToOption(contractor) : undefined}
              requiredPermission={UserResourcePermissions.Contract.Update}
              isRequired
            />
          </div>
          {contractor && (
            <div className="link">
              <button
                className="btn"
                type="button"
                onClick={() => navigate(NavigationRoutes.ContractorId(contractor.id), { state: location.state })}
              >
                <FontAwesomeIcon icon={faUpRightFromSquare} />
              </button>
            </div>
          )}
        </div>
        {contractor && <ContractorDetails contractor={contractor} />}
      </Collapsible>
    </LoadingSpinner>
  );
}
