import { useEffect, useRef, useState } from 'react';
import { faEllipsisH, faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import { Trans } from 'react-i18next';
import { FieldError } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { useQueryClient } from '@tanstack/react-query';
import { MutationKey, MutationPath, useDeleteMutation, usePatchMutation } from '../../react-query/MutationQueries';
import { Ticket } from '../../models/operation/TicketModel';
import {
  TicketingApiPrefix,
  TicketMediaUrlBuilders,
  useGetSeverities,
  useGetStates,
  useGetTasksByTicketId,
  useGetTicket,
  useGetTypes,
  useGetUser,
} from '../../react-query/TicketingSystemApi';
import { useTranslationText } from '../../translation/TranslationHooks';
import ActionBar from '../../ui/action-bar/ActionBar';
import EditableTextInput from '../../ui/editable-inputs/EditableTextInput';
import { Dropdown } from '../../ui/dropdown/Dropdown';
import TicketTabs from '../tabs/TicketTabs';
import {
  getSeverityIcon,
  getTypeIcon,
  mapSeveritiesToOptions,
  mapSeverityToOption,
  mapStatesToOptions,
  mapStateToOption,
  mapTypesToOptions,
  mapTypeToOption,
  mapUsersToOptions,
  mapUsersToSimpleOptions,
  mapUserToOption,
} from '../../models/operation/TicketFunctions';
import FormPatchWrapper from '../../ui/form-patch-wrapper/FormPatchWrapper';
import LoadingSpinner from '../../ui/loading-spinner/LoadingSpinner';
import { invalidateAllTickets, invalidateTicketHistory } from '../../react-query/InvalidationQueries';
import useYupLocal from '../../translation/YupLocal';
import ticketValidationSchema from '../TicketValidationSchema';
import SearchDropdown, { ReactSelectOption } from '../../ui/search-dropdown/SearchDropdown';
import UserRoleCheck, { useAuthUser } from '../../auth/UserRoleCheck';
import { UserResourcePermissions } from '../../auth/AuthUserRoles';
import Modal from '../../ui/modal/Modal';
import { Patch, replaceProperty } from '../../models/patch/PatchModel';
import useErrorsCollector from '../../custom-hooks/UseErrorsCollector';
import { UserModel } from '../../models/operation/UserModel';
import useGetAllUsers from '../../react-query/getUsers';
import TicketArticles from '../articles/TicketArticles';
import EditableRichTextEditor from '../../ui/editable-inputs/editable-richtext-edtior/EditableRichTextEditor';
import './TicketDetails.scss';
import { TicketState } from '../../models/operation/TicketStatesModel';
import Media from '../../ui/media/Media';
import { StrapiFile } from '../../models/strapi/StrapiFile';
import SingleSelectDropdown from '../../ui/single-select-dropdown/SingleSelectDropdown';
import { useGetComponent, useGetDescendentComponentStats } from '../../react-query/ComponentApi';
import { imageTagToDocument } from '../../ui/media/ImageToDocument';
import { TicketContracts } from '../contract/TicketContracts';
import { useLongParamId } from '../../custom-hooks/UseParamId';
import ComponentStatusTag from '../../components/component-status-tag/ComponentStatusTag';
import { ComponentDescendentComponents } from '../../components/component-detail-items/ComponentDescendentComponents';
import NavigationRoutes from '../../routing/NavigationRoutes';
import SingleComponentSelect from '../create-ticket/SingleComponentSelect/SingleComponentSelect';
import FavoriteInput from './favorite-input/FavoriteInput';
import { TicketAlerts } from '../alerts/TicketAlerts';
import PrintButton from '../../ui/print-button/PrintButton';
import { TaskState } from '../../models/operation/TaskDto';
import { formatDateAndTime } from '../../utils/dateFormatting';
import DetailInfoField from '../../ui/detail-info-field/DetailInfoField';
import { allowImagesAndPdf } from '../../models/media/Media';
import TicketRelations from '../ticket-relations/TicketRelations';
import AutogeneratedTicketInformation from '../ticket-autogenerated/AutogeneratedTicketInformation';
import { BaseComponentItem } from '../../models/operation/ComponentModel';
import { SwitchInput } from '../../ui/switch/SwitchInput';
import ActorTextInput from '../../ui/ActorTextInput';
import MultiSelectDropdown from '../../ui/multi-select-dropdown/MultiSelectDropdown';

type TicketErrors = {
  [k in keyof Ticket]?: string;
};

function formattedSeverityLabel(opt: ReactSelectOption) {
  return (
    <>
      <span className="severity-icon-wrapper">{getSeverityIcon(opt.value)}</span>
      {opt.label}
    </>
  );
}

function formattedTypeLabel(opt: ReactSelectOption) {
  return (
    <>
      <span className="type-icon-wrapper">{getTypeIcon(opt.value)}</span>
      {opt.label}
    </>
  );
}

function formattedStateLabel(opt: ReactSelectOption) {
  return <span data-role={`state-${opt.value}`}>{opt.label}</span>;
}

export default function TicketDetails() {
  const { id: ticketId, ErrorPage } = useLongParamId();

  const [ticket, setTicket] = useState<Ticket>();
  const [validationErrors, setValidationErrors] = useState<TicketErrors>({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isTaskHintModalVisible, setIsTaskHintModalVisible] = useState(false);
  const [lastHistoryUpdate, setLastHistoryUpdate] = useState(Date.now());
  // Utils
  const { t } = useTranslationText('tickets');
  const { t: tError } = useTranslationText('errorTexts');
  const { t: tSuccess } = useTranslationText('successTexts');
  const { t: tCommon } = useTranslationText('commons');
  const { yup } = useYupLocal();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { hasPermission } = useAuthUser();

  const { mutate: deleteMutate } = useDeleteMutation(MutationKey.DeleteTicket, {
    onSuccess: async () => {
      await invalidateAllTickets(queryClient).then(() => navigate(-1));
      toast.success(tSuccess('deleteTicket'));
    },
    onError: () => {
      toast.error(`${tError('error')}: ${tError('ticketDeleteError')}`);
    },
  });

  const { data: ticketComponent, isError: componentUnknown } = useGetComponent(ticket?.assignedComponent?.id!, {
    enabled: !!ticket?.assignedComponent,
  });

  const { data: descendentComponentStats, isLoading: isDescendentComponentStatsLoading } =
    useGetDescendentComponentStats(ticketComponent?.id || '', 'part-of', {
      enabled: !!ticketComponent && !componentUnknown,
    });

  const shouldShowStatusForDescendentComponents =
    descendentComponentStats &&
    descendentComponentStats.healthy + descendentComponentStats.disrupted + descendentComponentStats.failed > 0;

  // CurrentTicket
  const {
    data: currentTicket,
    isLoading: isTicketLoading,
    error: getTicketFetchError,
    refetch,
  } = useGetTicket(ticketId, {
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (currentTicket) {
      setTicket(currentTicket);
    }
  }, [currentTicket]);

  const { mutate, isPending: MutationIsLoading } = usePatchMutation<Patch, Ticket>(MutationKey.PatchTicket);
  const { data: severities, isLoading: isSeverityLoading, error: getSeverityFetchError } = useGetSeverities();
  const { data: states, isLoading: isStatesLoading, error: getStatesFetchError } = useGetStates();
  const { data: types, isLoading: isTypesLoading } = useGetTypes();
  const { data: users, isLoading: isAllUsersLoading, isError: isAllUsersError, setUserQuery } = useGetAllUsers();
  const {
    data: user,
    isLoading: isUserLoading,
    isError: isUserError,
  } = useGetUser(ticket?.assigneeId!, { enabled: !!ticket?.assigneeId });

  const onDelete = () => deleteMutate({ path: `${TicketingApiPrefix}/ticket/${ticketId}` });

  const { data: taskData, isLoading: areTasksLoading } = useGetTasksByTicketId(ticketId, { enabled: !!ticketId });
  const unfinishedTaskLength = taskData?.filter((task) => task.state !== TaskState.DONE)?.length ?? 0;

  // Benötigt um RTE und Media miteinander kommunizieren zu lassen
  const [rteField, setRTEField] = useState<string>('');

  // Save CurrentTicket
  function onSubmit(
    newValue: string | boolean | UserModel | BaseComponentItem | TicketState | StrapiFile[] | null,
    property: keyof Ticket,
    isFormValid: boolean = true,
    saveCompleted?: (endEditing: boolean) => void,
  ) {
    if (ticket !== undefined && isFormValid) {
      mutate(
        { body: [replaceProperty(property, newValue)], path: MutationPath.PatchTicket(ticket.id.toString()) },
        {
          onSuccess: async (res) => {
            setTicket(res);
            setValidationErrors({ ...validationErrors, [property]: undefined });
            saveCompleted?.(true);
            await refetch();
            await invalidateTicketHistory(queryClient, ticketId);
          },
          onError: () => {
            saveCompleted?.(false);
            toast.error(<Trans i18nKey="errorTexts.patchError.text" values={{ component: tError(property) }} />);
          },
        },
      );
    }
  }

  const errors = useErrorsCollector([
    {
      fetchError: getTicketFetchError,
      errorText: <Trans i18nKey="errorTexts.404_ticket.text" values={{ id: ticketId }} />,
    },
    {
      fetchError: getSeverityFetchError,
      errorText: '404_severity',
    },
    {
      fetchError: getStatesFetchError,
      errorText: '404_ticketsStates',
    },
  ]);

  const printRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    bodyClass: 'ticket-print',
    documentTitle: `E21X-BMS-${ticketId}-${ticket?.title ?? 'Unbekanntes Ticket'}`,
  });

  const handleTicketRelationRefresh = async () => {
    setLastHistoryUpdate(Date.now());
    await refetch();
  };

  const getItems = () => {
    const renderItems: JSX.Element[] = [];

    if (hasPermission(UserResourcePermissions.Ticket.Delete)) {
      renderItems.push(
        <button
          className="dropdown-item"
          onClick={() => (unfinishedTaskLength ? setIsTaskHintModalVisible(true) : setIsModalVisible(true))}
          data-role="ticket-delete-button"
          key="ticket-delete-button"
          type="button"
        >
          {t('ticketDelete')}
        </button>,
      );
    }

    return renderItems;
  };

  return (
    <LoadingSpinner isLoading={isTicketLoading} errors={[...errors, ErrorPage]}>
      <ActionBar
        right={
          <>
            <PrintButton onClick={() => handlePrint()} data-role="ticket-print-button" />
            <Dropdown title={<FontAwesomeIcon icon={faEllipsisH} />} renderItems={getItems} />
          </>
        }
      />
      <div ref={printRef} className="columns" data-role="ticket-details">
        <div className="ticket-details column is-7">
          {ticket && <AutogeneratedTicketInformation actorKind={ticket.author.kind} showInformationText />}

          <div className="wrapper">
            <div className="severities-dropdown">
              <SingleSelectDropdown
                dataRole="severities-dropdown"
                label={t('fieldSeverity')}
                requiredPermission={UserResourcePermissions.Ticket.Update}
                isLoading={isSeverityLoading || MutationIsLoading}
                options={mapSeveritiesToOptions(t, severities)}
                onChange={(newValue) => {
                  if (newValue && newValue?.value !== ticket?.severity) {
                    onSubmit(newValue.value, 'severity');
                  }
                }}
                value={mapSeverityToOption(
                  t,
                  severities?.find((severity) => severity.key === ticket?.severity),
                )}
                formatOptionLabel={formattedSeverityLabel}
              />
            </div>
            <div className="field is-fullwidth">
              <FormPatchWrapper<Ticket, string, FieldError>
                validationSchema={yup.object({ title: ticketValidationSchema(yup).fields.title })}
                patchProperty="title"
                defaultValue={ticket?.title}
                render={({ field }, property, isFormValid, error) => (
                  <EditableTextInput
                    error={error?.message}
                    label={t('titleLabel')}
                    isLoading={MutationIsLoading}
                    onChange={(newValue) => field.onChange(newValue)}
                    onAcceptNewValue={(value, callback) => onSubmit(value, property, isFormValid, callback)}
                    placeholder={t('titlePlaceholder')}
                    value={field.value}
                    requiredPermission={UserResourcePermissions.Ticket.Update}
                    dataRole="ticketTitle"
                    options={{
                      isRequired: true,
                    }}
                  />
                )}
              />
            </div>
          </div>

          <div className="component-wrapper">
            <div className="component-and-link-wrapper">
              <SingleComponentSelect
                onChange={(component) => {
                  if (ticket?.assignedComponent?.id !== component?.id) {
                    onSubmit(component, 'assignedComponent');
                  }
                }}
                value={ticketComponent || null}
              />
              {ticketComponent && (
                <>
                  <Link
                    to={NavigationRoutes.ComponentId(ticketComponent.id)}
                    className="without-label-wrapper"
                    title={t('linkToComponentTooltip', { componentName: ticketComponent.displayName })}
                  >
                    <FontAwesomeIcon icon={faUpRightFromSquare} />
                  </Link>

                  <div className="without-label-wrapper">
                    <ComponentStatusTag status={ticketComponent.status} />
                  </div>
                </>
              )}
            </div>
            {ticketComponent && (
              <div className="descendant-component-status-wrapper">
                {shouldShowStatusForDescendentComponents && (
                  <ComponentDescendentComponents
                    label={t('statusDescendentComponents')}
                    isLoading={isDescendentComponentStatsLoading}
                    descendentComponentStats={descendentComponentStats}
                  />
                )}
              </div>
            )}
          </div>
          {componentUnknown && <div>{t('unknownAssignedComponent')}</div>}

          <FormPatchWrapper<Ticket, string, FieldError>
            key={ticket?.id}
            patchProperty="description"
            defaultValue={ticket?.description}
            render={({ field }, property, isFormValid) => (
              <EditableRichTextEditor
                id="ticketDescription"
                label={t('descriptionLabel')}
                isLoading={MutationIsLoading}
                onAcceptNewValue={(value, callback) => onSubmit(value, property, isFormValid, callback)}
                onChange={(newValue) => {
                  field.onChange(newValue);
                  setRTEField(newValue);
                }}
                placeholder={t('descriptionPlaceholder')}
                value={field.value}
                requiredPermission={UserResourcePermissions.Ticket.Update}
              />
            )}
          />
          <FormPatchWrapper<Ticket, StrapiFile[], FieldError>
            patchProperty="media"
            defaultValue={ticket?.media}
            render={({ field }, property, isFormValid) => (
              <Media
                mutationPath={MutationPath.UploadTicketMedia}
                mutationKey={MutationKey.PostUploadTicketMedia}
                media={field.value}
                urlBuilders={TicketMediaUrlBuilders}
                label="Anhänge"
                isEditable
                isSideView
                supportedFileTypes={allowImagesAndPdf}
                onCopyImage={(url) => {
                  setTicket(
                    (oldTicket) =>
                      ({
                        ...oldTicket,
                        description: imageTagToDocument(url, rteField),
                      }) as Ticket,
                  );
                }}
                onMediaChange={(e) => {
                  field.onChange(e);
                  onSubmit(e, 'media', isFormValid);
                }}
              />
            )}
          />
          <div className="tabs-wrapper">
            <TicketTabs
              ticketId={ticketId}
              severities={severities ?? []}
              states={states ?? []}
              lastHistoryUpdate={lastHistoryUpdate}
            />
          </div>
        </div>
        <div className="ticket-details column is-5">
          <SingleSelectDropdown
            label={t('fieldState')}
            dataRole="ticket-state-dropdown"
            requiredPermission={UserResourcePermissions.Ticket.Update}
            isLoading={isTicketLoading || isStatesLoading || MutationIsLoading}
            options={mapStatesToOptions(t, states)}
            value={mapStateToOption(
              t,
              states?.find((state) => state.key === ticket?.state),
            )}
            onChange={(newValue) => {
              if (newValue && newValue.value !== ticket?.state) {
                onSubmit(newValue.value, 'state');
              }
            }}
            formatOptionLabel={formattedStateLabel}
          />
          <SingleSelectDropdown
            dataRole="type-dropdown"
            label={t('fieldType')}
            requiredPermission={UserResourcePermissions.Ticket.Update}
            isLoading={isTypesLoading}
            options={mapTypesToOptions(t, types)}
            onChange={() => {}}
            value={mapTypeToOption(
              t,
              types?.find((type) => type.key === ticket?.type),
            )}
            formatOptionLabel={formattedTypeLabel}
            disabled
          />

          {ticket && <ActorTextInput actor={ticket.author} label={t('fieldAuthor')} readOnly />}

          <SearchDropdown<UserModel | null>
            dataRole="assigned-user-select"
            isError={isAllUsersError || isUserError}
            isLoading={isAllUsersLoading || isUserLoading}
            label={t('assignedUser')}
            noOptionsMessage={t('noSuggestionsUsers')}
            options={mapUsersToOptions(users?.content)}
            onInputChange={setUserQuery}
            isLoadingMessage={t('usersAreLoading')}
            mapValueToSelectOption={mapUserToOption}
            value={user || null}
            placeholder={t('assignUser')}
            onChange={(newValue) => {
              if (ticket?.assigneeId !== newValue?.value) {
                onSubmit(newValue?.value || null, 'assigneeId');
              }
            }}
            isRequired
            requiredPermission={UserResourcePermissions.Ticket.Update}
          />
          <MultiSelectDropdown
            dataRole="observers-select"
            options={mapUsersToOptions(users?.content)}
            onChange={(selectedOptions) => onSubmit(selectedOptions.map((option) => option.value) ?? [], 'observerIds')}
            requiredPermission={UserResourcePermissions.Ticket.Update}
            isError={isAllUsersError || isUserError}
            isLoading={isAllUsersLoading || isUserLoading}
            mappedValues={mapUsersToSimpleOptions(
              users?.content.filter((option) => ticket?.observerIds?.includes(option.id)),
            )}
            noOptionsMessage={t('noSuggestionsUsers')}
            loadingMessage={t('usersAreLoading')}
            placeholder={t('assignObservers')}
            label={t('assignedObservers')}
          />

          {ticket && <TicketRelations ticket={ticket} onRefresh={handleTicketRelationRefresh} />}

          {ticket && (
            <div className="form-row">
              <div className="is-flex-grow">
                <UserRoleCheck
                  requiredPermission={UserResourcePermissions.TicketFavorite.Create.and(
                    UserResourcePermissions.TicketFavorite.Delete,
                  )}
                >
                  <FavoriteInput ticket={ticket} />
                </UserRoleCheck>
              </div>
              <div className="is-flex-grow">
                <UserRoleCheck
                  requiredPermission={UserResourcePermissions.TicketReportableProcess.Create.and(
                    UserResourcePermissions.TicketReportableProcess.Delete,
                  )}
                >
                  <SwitchInput
                    id={`ticket-reportable-process-${ticketId}`}
                    onChange={(newValue) => onSubmit(newValue, 'reportableProcess')}
                    checked={ticket.reportableProcess}
                    label={t('reportableProcess')}
                    secondLabel={ticket.reportableProcess ? 'Ja' : 'Nein'}
                  />
                </UserRoleCheck>
              </div>
            </div>
          )}

          <div className="form-row">
            <DetailInfoField
              className="is-flex-grow"
              label={t('createdAtLabel')}
              value={ticket ? formatDateAndTime(ticket.createdAt) : '-'}
            />
            <DetailInfoField
              className="is-flex-grow"
              label={t('modifiedAtLabel')}
              value={ticket ? formatDateAndTime(ticket.lastModified) : '-'}
            />
          </div>
          <div className="ticket-details">
            <TicketContracts component={ticketComponent} taskData={taskData} areTasksLoading={areTasksLoading} />
            <TicketArticles ticketComponent={ticketComponent} />
            <UserRoleCheck requiredPermission={UserResourcePermissions.TicketAlerts.Read}>
              <TicketAlerts ticketId={ticketId} />
            </UserRoleCheck>
          </div>
        </div>
      </div>
      <Modal
        isVisible={isModalVisible}
        title={t('ticketDelete')}
        cancelBtnText={tCommon('abort')}
        confirmBtnText={tCommon('delete')}
        onCancel={() => setIsModalVisible(false)}
        onConfirm={onDelete}
        onClose={() => setIsModalVisible(false)}
      >
        <p>{t('warningTicketDelete')}</p>
      </Modal>
      <Modal
        isVisible={isTaskHintModalVisible}
        title={t('ticketCantBeDeleted')}
        confirmBtnText={tCommon('ok')}
        onClose={() => setIsTaskHintModalVisible(false)}
        onConfirm={() => setIsTaskHintModalVisible(false)}
        hideCancelButton
      >
        {t('ticketHasOpenTaskHints')}
      </Modal>
    </LoadingSpinner>
  );
}
