import React, { useState } from 'react';
import { MultiValue } from 'react-select';
import { useTranslationText } from '../../../translation/TranslationHooks';
import { ReactSelectOption } from '../../../ui/search-dropdown/SearchDropdown';
import MultiSelectDropdown from '../../../ui/multi-select-dropdown/MultiSelectDropdown';
import { useGetComponentTypes } from '../../../react-query/ComponentTypeApi';
import { BaseComponentType } from '../../../models/operation/ComponentTypeModel';
import { sortAndMapComponentTypesToOption } from '../../../models/operation/ComponentTypeFunctions';

interface ArticleComponentTypeSelectionProps {
  onChange: (selectedOptions: BaseComponentType[]) => void;
  values: BaseComponentType[];
}

export function ArticleComponentTypeSelection({ onChange, values }: ArticleComponentTypeSelectionProps) {
  const [componentTypesQuery, setComponentTypesQuery] = useState<string>('');
  const [selectedComponentTypes, setSelectedComponentTypes] = useState<MultiValue<ReactSelectOption>>(
    sortAndMapComponentTypesToOption(values),
  );
  const {
    data: componentTypes,
    isLoading: componentTypesLoading,
    isError: componentTypesError,
  } = useGetComponentTypes();
  const { t } = useTranslationText('knowledgeBases');

  const onOptionSelect = (selectedOptions: MultiValue<ReactSelectOption>): void => {
    setSelectedComponentTypes(selectedOptions);
    onChange(
      selectedOptions.map((selectedOption) => ({
        id: selectedOption.value,
        name: selectedOption.label,
      })),
    );
  };

  return (
    <div>
      <label className="label" htmlFor={t('componentType')}>
        {t('componentType')}
      </label>
      <MultiSelectDropdown
        dataRole="component-type-select"
        isError={componentTypesError}
        isLoading={componentTypesLoading}
        noOptionsMessage={t('componentTypeNoOptionMessage')}
        onChange={onOptionSelect}
        onInputChange={setComponentTypesQuery}
        inputValue={componentTypesQuery}
        options={sortAndMapComponentTypesToOption(componentTypes?.content)}
        loadingMessage={t('isLoadingMessage')}
        placeholder={t('componentTypePlaceholder')}
        openMenuOnClick
        mappedValues={selectedComponentTypes}
      />
    </div>
  );
}
