import { ControlProps, GroupBase, OptionProps } from 'react-select';
import classNames from 'classnames';
import { BulmaSize } from '@aos/styleguide-react/dist/common/constants';

export const singleStyles = {
  indicatorSeparator: () => ({ display: 'none' }),
  clearIndicator: () => ({ display: 'none' }),
  control: () => ({ paddingLeft: 0 }),
  dropdownIndicator: () => ({}),
  indicatorsContainer: () => ({}),
  option: () => ({ display: 'flex', gap: '0.5rem', cursor: 'pointer' }),
  menuList: (existingStyles: any) => ({ ...existingStyles, overflow: 'auto' }),
  menu: () => ({}),
  container: () => ({ gridArea: 'content' }),
  valueContainer: (existingStyles: any) => ({
    ...existingStyles,
    paddingLeft: '0.5em',
    paddingRight: '0.75em',
    paddingTop: '0.5em',
    paddingBottom: '0.5em',
    lineHeight: 1.5,
  }),
  singleValue: (existingStyles: any) => ({ ...existingStyles, display: 'flex', gap: '0.5rem' }),
};

export const singleClassNames = <T,>(size: BulmaSize) => ({
  control: (state: ControlProps<T, false, GroupBase<T>>) =>
    classNames(
      'aos-select-trigger',
      state.isFocused && 'is-focused',
      state.menuIsOpen && 'is-open',
      state.isDisabled && 'is-disabled',
      size,
    ),
  menuList: () => 'aos-select-content scroll-area-root scroll-area-viewport',
  option: (state: OptionProps<T, false, GroupBase<T>>) =>
    classNames(
      'select-item',
      state.isSelected && 'is-active',
      state.isFocused && 'is-hovered',
      state.isDisabled && 'is-disabled',
      size,
    ),
  indicatorsContainer: () => 'select-icon',
  dropdownIndicator: () => 'aos-icon',
  menuPortal: () => 'react-select-menu-portal',
});
