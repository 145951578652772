import { PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { LoadingIndicator } from '@aos/styleguide-react';
import { useNavigate } from 'react-router';
import UserInfo from '../../models/user/UserInfo';
import { CurrentTenantContext, CurrentTenantContextType } from './CurrentTenantContext';
import { jsonContentType } from '../../react-query/CustomFetch';
import TenantEnum from './TenantEnum';

export function CurrentTenantProvider({ children }: PropsWithChildren) {
  const [currentTenant, setCurrentTenant] = useState<TenantEnum | undefined>();
  const { user: authUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchUserInfo() {
      if (!authUser) {
        return;
      }

      const response = await fetch('/api/ticketing/user/info', {
        headers: {
          'Content-Type': jsonContentType,
          Accept: jsonContentType,
          Authorization: `Bearer ${authUser.access_token}`,
        },
      });

      const user: UserInfo = await response.json();
      setCurrentTenant(user.currentTenant);
    }

    void fetchUserInfo();
  }, [authUser]);

  const handleTenantChange = useCallback(
    async (newTenant: TenantEnum | undefined) => {
      if (!newTenant || newTenant === currentTenant || !authUser) {
        return;
      }
      setCurrentTenant(newTenant);

      await fetch('/api/ticketing/user/tenant', {
        headers: {
          'Content-Type': jsonContentType,
          Accept: jsonContentType,
          Authorization: `Bearer ${authUser.access_token}`,
        },
        method: 'PUT',
        body: JSON.stringify({ tenant: newTenant }),
      });

      navigate('/');
    },
    [authUser, currentTenant, navigate],
  );

  const context = useMemo((): CurrentTenantContextType | undefined => {
    if (currentTenant === undefined) {
      return undefined;
    }

    return {
      currentTenant,
      setCurrentTenant: handleTenantChange,
    };
  }, [currentTenant, handleTenantChange]);

  if (!context) {
    // eslint-disable-next-line react/style-prop-object
    return <LoadingIndicator style="street" isFullscreen />;
  }

  return <CurrentTenantContext.Provider value={context}>{children}</CurrentTenantContext.Provider>;
}
