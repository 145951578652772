import React, { useEffect, useState } from 'react';
import { InputActionMeta, SingleValue, StylesConfig } from 'react-select';
import './SearchDropdown.scss';
import { useAuthUser } from '../../auth/UserRoleCheck';
import { UserPermission } from '../../auth/UserPermission';
import SingleSelectDropdown from '../single-select-dropdown/SingleSelectDropdown';

// TODO ADB-558: refactor ReactSelectOption
export interface ReactSelectOption {
  readonly label: string;
  readonly value: any;
}

interface SearchDropdownProps<T> {
  dataRole?: string;
  isError?: boolean;
  isLoading?: boolean;
  label?: string;
  noOptionsMessage?: string;
  isLoadingMessage?: string;
  options: ReactSelectOption[];
  onChange: (selectedOption: SingleValue<ReactSelectOption>) => void;
  placeholder?: string;
  value?: T;
  onInputChange?: (value: string, action?: InputActionMeta) => void;
  tabIndex?: number;
  autoFocus?: boolean;
  mapValueToSelectOption?: (value: T) => SingleValue<ReactSelectOption>;
  requiredPermission: UserPermission;
  hideDropDownButton?: boolean;
  isClearable?: boolean;
  openMenuOnClick?: boolean;
  isSmall?: boolean;
  isRequired?: boolean;
  isDisabled?: boolean;
}

export default function SearchDropdown<T>({
  dataRole,
  isError,
  isLoading,
  label,
  noOptionsMessage,
  isLoadingMessage,
  options,
  onChange,
  placeholder,
  value,
  onInputChange,
  tabIndex,
  autoFocus,
  mapValueToSelectOption,
  requiredPermission,
  hideDropDownButton = false,
  isClearable = false,
  openMenuOnClick = true,
  isSmall,
  isRequired = false,
  isDisabled = false,
}: SearchDropdownProps<T>): React.ReactElement<SearchDropdownProps<T>> {
  const [selectOption, setSelectOption] = useState<SingleValue<ReactSelectOption | undefined>>(undefined);
  const { hasPermission } = useAuthUser();

  useEffect(() => {
    if (value && mapValueToSelectOption) {
      setSelectOption(mapValueToSelectOption(value));
    } else {
      setSelectOption(undefined);
    }
  }, [mapValueToSelectOption, value]);

  const colourStyles: StylesConfig<ReactSelectOption> = {
    menu: (styles) => ({ ...styles, display: value ? 'none' : 'block' }),
    dropdownIndicator: (styles) => ({ ...styles, display: 'none' }),
    indicatorSeparator: (styles) => ({ ...styles, display: 'none' }),
  };

  return (
    <SingleSelectDropdown
      options={options}
      onChange={onChange}
      requiredPermission={requiredPermission}
      dataRole={dataRole}
      isClearable={isClearable}
      isSearchable
      isLoading={isLoading}
      openMenuOnClick={openMenuOnClick}
      onInputChange={onInputChange}
      styles={hideDropDownButton ? colourStyles : undefined}
      size={isSmall ? 'is-small' : 'is-normal'}
      tabIndex={tabIndex}
      value={selectOption}
      disabled={isDisabled || !hasPermission(requiredPermission)}
      autoFocus={autoFocus}
      isRequired={isRequired}
      placeholder={placeholder}
      isLoadingMessage={() => isLoadingMessage}
      noOptionsMessage={() => noOptionsMessage}
      label={label}
      isError={isError}
    />
  );
}
