import React, { useRef } from 'react';
import classNames from 'classnames';
import Select, {
  ActionMeta,
  createFilter,
  InputActionMeta,
  SelectInstance,
  SingleValue,
  StylesConfig,
} from 'react-select';
import './SingleSelectDropdown.scss';
import { Control, Field, Label } from '@aos/styleguide-react';
import { BulmaSize } from '@aos/styleguide-react/dist/common/constants';
import { useAuthUser } from '../../auth/UserRoleCheck';
import { UserPermission } from '../../auth/UserPermission';
import { singleClassNames, singleStyles } from '../single-select-dropdown-utils/SingleSelectDropdownUtils';

export interface ReactSelectOption<T> {
  readonly label: string;
  readonly value: T;
}

interface SingleSelectDropdownProps<T> {
  dataRole?: string;
  isError?: boolean;
  isLoading?: boolean;
  label?: string;
  options: ReactSelectOption<T>[];
  onChange: (selectedOption: SingleValue<ReactSelectOption<T>>, actionMeta: ActionMeta<ReactSelectOption<T>>) => void;
  placeholder?: string;
  value?: SingleValue<ReactSelectOption<T>>;
  tabIndex?: number;
  autoFocus?: boolean;
  formatOptionLabel?: any;
  requiredPermission: UserPermission;
  disabled?: boolean;
  isRequired?: boolean;
  isClearable?: boolean;
  size?: BulmaSize;
  isSearchable?: boolean;
  openMenuOnClick?: boolean;
  onInputChange?: (value: string, action?: InputActionMeta) => void;
  styles?: StylesConfig<ReactSelectOption<T>>;
  isLoadingMessage?: () => string | undefined;
  noOptionsMessage?: () => string | undefined;
}

export default function SingleSelectDropdown<T>({
  dataRole,
  isError,
  isLoading = false,
  label,
  options,
  onChange,
  placeholder,
  value,
  tabIndex,
  autoFocus,
  requiredPermission,
  formatOptionLabel,
  disabled,
  isRequired,
  isClearable,
  size = 'is-normal',
  isSearchable = false,
  openMenuOnClick = true,
  onInputChange,
  styles,
  isLoadingMessage,
  noOptionsMessage,
}: SingleSelectDropdownProps<T>) {
  const { hasPermission } = useAuthUser();

  const selectInputRef = useRef<SelectInstance<ReactSelectOption<T>>>(null);

  const isDisabled = disabled || (requiredPermission && !hasPermission(requiredPermission));

  const onClear = () => {
    selectInputRef.current?.clearValue();
  };

  function handleChange(newValue: SingleValue<ReactSelectOption<T>>, actionMeta: ActionMeta<ReactSelectOption<T>>) {
    if (newValue?.value === value?.value && !isRequired) {
      onClear();
      return;
    }
    onChange(newValue, actionMeta);
  }

  return (
    <Field data-role={dataRole} className="is-fullwidth">
      {label && (
        <Label className={classNames({ 'is-required': isRequired })} htmlFor={label} size={size}>
          {label}
        </Label>
      )}
      <Control className="is-fullwidth">
        <Select
          ref={selectInputRef}
          className={classNames({
            'is-error': isError,
          })}
          classNamePrefix="react-select"
          options={options}
          isDisabled={isDisabled}
          isLoading={isLoading}
          onChange={handleChange as any} // Cast ist notwendig, sonst Fehler
          placeholder={placeholder}
          inputId="select-input"
          tabIndex={tabIndex}
          autoFocus={autoFocus}
          value={value}
          formatOptionLabel={formatOptionLabel}
          filterOption={createFilter({ stringify: (option) => option.label })}
          isClearable={isClearable}
          styles={styles ?? singleStyles}
          classNames={singleClassNames<ReactSelectOption<T>>(size)}
          isMulti={false}
          menuPortalTarget={document.body}
          menuShouldScrollIntoView={false}
          isSearchable={isSearchable}
          openMenuOnClick={openMenuOnClick}
          onInputChange={onInputChange}
          required={isRequired}
          noOptionsMessage={noOptionsMessage}
          loadingMessage={isLoadingMessage}
        />
      </Control>
    </Field>
  );
}
