import classNames from 'classnames';
import React, { ComponentType, useEffect, useState } from 'react';
import Select, { GroupBase, MultiValue, MultiValueRemoveProps } from 'react-select';
import { BulmaSize } from '@aos/styleguide-react/dist/common/constants';
import { Control, Field, Label } from '@aos/styleguide-react';
import { useAuthUser } from '../../auth/UserRoleCheck';
import { UserPermission } from '../../auth/UserPermission';
import {
  createCustomMultiValueRemove,
  DropdownIndicator,
  multiClassNames,
  multiStyles,
} from '../multi-dropdowns-utils/MultiDropdownUtils';

export interface ReactSelectOption<T> {
  readonly label: string;
  readonly value: T;
}

export function sortByLabel<T>(o1: ReactSelectOption<T>, o2: ReactSelectOption<T>) {
  return o1.label.localeCompare(o2.label);
}

interface MultiSelectDropdownProps<T> {
  dataRole: string;
  isError?: boolean;
  isLoading?: boolean;
  label?: string;
  noOptionsMessage?: string;
  loadingMessage?: string;
  options: ReactSelectOption<T>[];
  onChange: (selectedOptions: MultiValue<ReactSelectOption<T>>) => void;
  onBlur?: () => void;
  placeholder?: string;
  mappedValues?: MultiValue<ReactSelectOption<T>>;
  inputValue?: string;
  formatOptionLabel?: any;
  isDropdownIndicatorEnabled?: boolean;
  isSearchable?: boolean;
  openMenuOnClick?: boolean;
  onInputChange?: (value: string) => void;
  disabled?: boolean;
  requiredPermission?: UserPermission;
  size?: BulmaSize;
}

export default function MultiSelectDropdown<T>({
  dataRole,
  isError,
  isLoading,
  label,
  noOptionsMessage,
  loadingMessage,
  options,
  onChange,
  onBlur,
  placeholder,
  mappedValues,
  formatOptionLabel,
  isDropdownIndicatorEnabled = true,
  isSearchable,
  openMenuOnClick = false,
  inputValue,
  onInputChange = () => {},
  disabled,
  requiredPermission,
  size = 'is-normal',
}: MultiSelectDropdownProps<T>): React.ReactElement<MultiSelectDropdownProps<T>> {
  const [selectOption, setSelectOption] = useState<MultiValue<ReactSelectOption<T>>>();
  const { hasPermission } = useAuthUser();

  const [menuIsClosed, setMenuIsClosed] = useState(true);

  const handleMenuOpen = () => setMenuIsClosed(false);
  const handleMenuClose = () => setMenuIsClosed(true);

  function handleChange(newValue: MultiValue<ReactSelectOption<T>>) {
    onChange(newValue);
  }

  useEffect(() => {
    if (mappedValues && mappedValues.length > 0) {
      setSelectOption(mappedValues);
    } else {
      setSelectOption([]);
    }
  }, [mappedValues]);

  const isDisabled = disabled || (requiredPermission && !hasPermission(requiredPermission));

  return (
    <Field data-role={dataRole}>
      {label && (
        <Label htmlFor={label} size={size}>
          {label}
        </Label>
      )}
      <Control size={size}>
        <Select
          className={classNames('aos-multiple-select', size, {
            'is-error': isError,
            'is-closed': menuIsClosed,
          })}
          classNamePrefix="react-select"
          options={options}
          isDisabled={isDisabled}
          isLoading={isLoading}
          loadingMessage={() => loadingMessage}
          openMenuOnClick={openMenuOnClick}
          isSearchable={isSearchable}
          noOptionsMessage={() => noOptionsMessage}
          // hier muss leider der Cast ausgeführt werden, sonst wirft React-Select einen unnötigen nicht behebbaren Fehler
          onChange={handleChange as any}
          onBlur={onBlur}
          placeholder={placeholder}
          inputId="select-input"
          onInputChange={onInputChange}
          inputValue={inputValue}
          formatOptionLabel={formatOptionLabel}
          hideSelectedOptions={false}
          value={selectOption}
          closeMenuOnSelect={false}
          blurInputOnSelect={false}
          onMenuOpen={handleMenuOpen}
          onMenuClose={handleMenuClose}
          isMulti
          unstyled
          components={{
            MultiValueRemove: createCustomMultiValueRemove(size) as
              | ComponentType<MultiValueRemoveProps<ReactSelectOption<T>, true, GroupBase<ReactSelectOption<T>>>>
              | undefined,
            ...(!isDropdownIndicatorEnabled ? { DropdownIndicator: null } : { DropdownIndicator }),
          }}
          styles={multiStyles}
          classNames={multiClassNames<ReactSelectOption<T>>()}
        />
      </Control>
    </Field>
  );
}
