import { MultiValue } from 'react-select';
import { BulmaSize } from '@aos/styleguide-react/dist/common/constants';
import { useTranslationText } from '../../../translation/TranslationHooks';
import { useAuthUser } from '../../../auth/UserRoleCheck';
import useGetAllUsers from '../../../react-query/getUsers';
import { ReactSelectOption } from '../../search-dropdown/SearchDropdown';
import MultiSelectDropdown from '../../multi-select-dropdown/MultiSelectDropdown';
import { getUserSelectOptions } from './UserSelectOptionMappings';

interface AuthorUserFilterProps {
  selectedUserIds: string[];
  onChange: (name: 'authors', value: string[]) => void;
  size?: BulmaSize;
}

export default function AuthorUserFilter({ selectedUserIds, onChange, size = 'is-small' }: AuthorUserFilterProps) {
  const { t } = useTranslationText('tickets');
  const { userId: currentUserId } = useAuthUser();

  const {
    data: users,
    isLoading: areUserOptionsLoading,
    isError: isUsersError,
    setUserQuery,
    userQuery,
  } = useGetAllUsers();

  const options = getUserSelectOptions(currentUserId, { value: 'system', label: t('systemUser') }, users?.content);

  const selectedOptions = options.filter((option) => selectedUserIds?.includes(option?.value));

  const handleOnChange = (newSelectedUsers: MultiValue<ReactSelectOption>): void => {
    onChange(
      'authors',
      newSelectedUsers.map((value) => value.value),
    );
  };

  return (
    <MultiSelectDropdown
      dataRole="authors-user-select"
      isError={isUsersError}
      isLoading={areUserOptionsLoading}
      noOptionsMessage={t('noSuggestionsUsers')}
      onChange={handleOnChange}
      onInputChange={setUserQuery}
      inputValue={userQuery}
      options={options}
      loadingMessage={t('usersAreLoading')}
      placeholder={t('selectAuthor')}
      openMenuOnClick
      mappedValues={selectedOptions}
      label={t('fieldAuthor')}
      size={size}
    />
  );
}
