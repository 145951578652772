import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { GroupBase, SingleValue } from 'react-select';
import { AsyncPaginate, Response } from 'react-select-async-paginate';
import '../search-dropdown/SearchDropdown.scss';
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters';
import { Control, Field, Label } from '@aos/styleguide-react';
import { useAuthUser } from '../../auth/UserRoleCheck';
import { UserPermission } from '../../auth/UserPermission';
import { JSXSelectOption } from '../jsx-search-dropdown/JSXSearchDropdown';
import { singleClassNames, singleStyles } from '../single-select-dropdown-utils/SingleSelectDropdownUtils';

interface JSXSearchDropdownPaginatedProps<T> {
  dataRole?: string;
  isError?: boolean;
  label?: string;
  noOptionsMessage?: string;
  isLoadingMessage?: string;
  onChange: (selectedOption: SingleValue<JSXSelectOption>) => void;
  placeholder?: string;
  value?: T;
  inputValue?: string;
  onInputChange?: (value: string) => void;
  tabIndex?: number;
  autoFocus?: boolean;
  mapValueToSelectOption?: (value: T) => SingleValue<JSXSelectOption>;
  requiredPermission: UserPermission;
  isClearable?: boolean;
  isSearchable?: boolean;
  openMenuOnClick?: boolean;
  isSmall?: boolean;
  isRequired?: boolean;
  loadOptions: (searchInput: string) => Promise<Response<JSXSelectOption, GroupBase<JSXSelectOption>, any>>;
  isDisabled?: boolean;
  filterOption?: (option: FilterOptionOption<JSXSelectOption>, inputValue: string) => boolean;
  cacheUniqs?: readonly unknown[];
}

export default function JSXSearchDropdownPaginated<T>({
  dataRole,
  isError,
  label,
  noOptionsMessage,
  isLoadingMessage,
  onChange,
  placeholder,
  value,
  inputValue,
  onInputChange,
  tabIndex,
  autoFocus,
  mapValueToSelectOption,
  requiredPermission,
  isClearable = false,
  isSearchable,
  openMenuOnClick = true,
  isSmall,
  isRequired = false,
  loadOptions,
  isDisabled = false,
  filterOption,
  cacheUniqs,
}: JSXSearchDropdownPaginatedProps<T>): React.ReactElement<JSXSearchDropdownPaginatedProps<T>> {
  const [selectOption, setSelectOption] = useState<SingleValue<JSXSelectOption | undefined>>(undefined);
  const { hasPermission } = useAuthUser();

  function handleChange(newValue: SingleValue<JSXSelectOption>) {
    onChange(newValue);
  }

  useEffect(() => {
    if (value && mapValueToSelectOption) {
      setSelectOption(mapValueToSelectOption(value));
    } else {
      setSelectOption(undefined);
    }
  }, [mapValueToSelectOption, value]);

  return (
    <Field className="is-fullwidth" data-role={dataRole}>
      {label && (
        <Label className={classNames({ 'is-required': isRequired })} htmlFor={label}>
          {label}
        </Label>
      )}
      <Control className="is-fullwidth">
        <AsyncPaginate<JSXSelectOption, GroupBase<JSXSelectOption>, any, false>
          className={classNames({
            'is-error': isError,
            'is-small': isSmall,
          })}
          classNamePrefix="react-select"
          isClearable={isClearable}
          loadingMessage={() => isLoadingMessage}
          onInputChange={onInputChange}
          inputValue={inputValue}
          openMenuOnClick={openMenuOnClick}
          isSearchable={isSearchable}
          noOptionsMessage={() => (inputValue?.length === 0 ? placeholder : noOptionsMessage)}
          // hier muss leider der Cast ausgeführt werden, sonst wirft React-Select einen unnötigen nicht behebaren Fehler
          onChange={handleChange as any}
          placeholder={placeholder}
          inputId="select-input"
          tabIndex={tabIndex}
          autoFocus={autoFocus}
          value={selectOption}
          isDisabled={!hasPermission(requiredPermission) || isDisabled}
          loadOptions={loadOptions}
          cacheUniqs={cacheUniqs}
          styles={singleStyles}
          classNames={singleClassNames<JSXSelectOption>(isSmall ? 'is-small' : 'is-normal')}
          isMulti={false}
          menuPortalTarget={document.body}
          menuShouldScrollIntoView={false}
          required={isRequired}
          filterOption={filterOption}
        />
      </Control>
    </Field>
  );
}
